import { AreYouSurePrompt } from '../modals/AreYouSurePrompt';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import { Navbar } from '../components/Navbar';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

type Props = {};

const MainHeaderSection = styled.div`
  padding: 120px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    font-family: 'Fira Mono';
  }
  .silver {
    color: var(--guts-color-light);
    font-weight: 400;
    opacity: 0.8;
  }
`;

export const Home: React.FC<Props> = () => {
  return (
    <div>
      <Head>
        <title>GutsJS</title>
      </Head>
      <Navbar />
      <MainHeaderSection>
        <h1>
          <span className='silver'>The</span> CMS{' '}
          <span className='silver'>built for</span> <br />
          <span className='silver'>that </span>Your Clients ❤️
          <br />
          <span className='blue'>&&</span> Developers ❤️❤️❤️
        </h1>
      </MainHeaderSection>
      [WYSIWYG] ^
    </div>
  );
};

export default Home;

// export const getStaticProps: GetStaticProps = async () => {
//   return {
//     // revalidate: 43200, // 12 hours
//     props: {
//       data,
//     },
//   };
// };
