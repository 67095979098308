import { ButtonBase } from './ButtonBase';
import Link from 'next/link';
import styled from 'styled-components';
import { useRouter } from 'next/router';

type Props = {};

const Container = styled.div`
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  padding: 6px 16px;
`;

const NavbarButton = styled(ButtonBase)`
  border-radius: 4px;
  background: transparent;
  color: var(--guts-color-light);
  font-family: 'Fira Mono';
  padding: 10px 22px;
  &.selected {
    color: var(--guts-color-blue);
    &::before {
      content: '';
      position: absolute;
      bottom: 6px;
      height: 1px;
      width: 70%;
      left: 15%;
      background: var(--guts-color-blue);
    }
    &::after {
      z-index: 0;
      content: '';
      position: absolute;
      bottom: -25px;
      left: 12px;
      height: 30px;
      width: 70%;
      background: linear-gradient(
        270deg,
        rgba(248, 42, 42, 1) 0.06%,
        rgba(30, 198, 252, 1) 99.95%
      );
      filter: blur(27px);
      opacity: 0.2;
    }
  }
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const SignupButton = styled(NavbarButton)`
  background: var(--guts-color-light);
  color: var(--guts-color-dark);
  font-weight: 700;
  &:hover {
    background: var(--guts-color-light);
    opacity: 0.9;
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;
  flex: 0.5;
  padding: 10px;
  img {
    width: 67px;
  }
`;

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AuthButtonsContainer = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Navbar: React.FC<Props> = () => {
  const router = useRouter();

  return (
    <Container>
      <Link href='/'>
        <LogoContainer>
          <img src='/gutsjs_logo.png' alt='GutsJS' />
        </LogoContainer>
      </Link>
      <ButtonsContainer>
        <Link href='/'>
          <NavbarButton className={router.pathname === '/' ? 'selected' : ''}>
            Home
          </NavbarButton>
        </Link>
        <Link href='/pricing'>
          <NavbarButton
            className={router.pathname === '/pricing' ? 'selected' : ''}
          >
            Pricing
          </NavbarButton>
        </Link>
        <Link href='/docs'>
          <NavbarButton
            className={router.pathname === '/docs' ? 'selected' : ''}
          >
            Docs
          </NavbarButton>
        </Link>
        <Link href='/changelog'>
          <NavbarButton
            className={router.pathname === '/changelog' ? 'selected' : ''}
          >
            Changelog
          </NavbarButton>
        </Link>
      </ButtonsContainer>
      <AuthButtonsContainer>
        <Link href='/auth?intent=login'>
          <NavbarButton>Login</NavbarButton>
        </Link>
        <Link href='/auth?intent=signup'>
          <SignupButton>Sign up</SignupButton>
        </Link>
      </AuthButtonsContainer>
    </Container>
  );
};
