import styled from 'styled-components';

export const ButtonBase = styled.button<{ small?: boolean }>`
  outline: none;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  padding: ${({ small }) => (small ? '3px 7px' : '4px 10px')};
  margin: ${({ small }) => (small ? '3px' : '5px')};
  text-align: center;
  display: inline-block;
  position: relative;
  font-size: ${({ small }) => (small ? '13px' : '16px')};
`;
